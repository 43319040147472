import "../../style/datatable.css";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";
import PopupAlert from "../../components/popupalert/popupAlert";
import { CircularProgress } from "@mui/material";
import SureModal from "../../components/SureModal/SureModal";

const DatatableMedicines = () => {
  const [medicines, setmedicines] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [popUpShow, setPopupshow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [popUpText, setPopupText] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [images, setImages] = useState([]);
  const [openImageModal, setImageModal] = useState(false);
  const [updatedImages, setUpdatedImage] = useState([]);
  const [selectedId, setSelectedId] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);

  useEffect(() => {
    axios
      .get("https://paulbrooksapi.doctorsforhealth.co.uk/medicines")
      .then((response) => {
        setmedicines(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [medicines]);

  const handleDelete = (id) => {
    axios
      .delete(
        "https://paulbrooksapi.doctorsforhealth.co.uk/medicines/delete/" + id
      )
      .then((response) => {
        console.log(response.data);
        setPopupshow(true);
        setPopupText("Medicine Deleted");
        setTimeout(() => {
          setPopupshow(false);
        }, 2000);
        setTimeout(() => {});
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDeleteSelectedRows = () => {
    selectedRows.forEach((row) => {
      axios
        .delete(
          "https://paulbrooksapi.doctorsforhealth.co.uk/medicines/delete/" + row
        )
        .then((response) => {
          window.location.reload();
        });
    });
    setPopupshow(true);
    setPopupText(`${selectedRows.length} Medicines Deleted`);
    setTimeout(() => {
      setPopupshow(false);
    }, 2000);
    setSelectedRows([]);
  };

  const handleAdd = (e) => {
    e.preventDefault();

    const formData = new FormData();

    images.forEach((image) => {
      formData.append("images", image);
    });
    if (images.length + updatedImages.length < 6) {
      axios
        .patch(
          `https://paulbrooksapi.doctorsforhealth.co.uk/medicines/addImage/${selectedRow._id}`,
          formData
        )
        .then((response) => {
          setUpdatedImage(response.data);
          setPopupText("New images added");
          setPopupshow(true);
          setTimeout(() => {
            setPopupshow(false);
            window.location.reload();
          }, 2000);
        })
        .catch((error) => {
          console.error(error);
        });
      setTimeout(() => {
        setPopupshow(false);
      }, 2000);
    } else {
      window.alert("No maximum 5 imaegs allowed");
    }
  };

  const handleDeleteImage = (index) => {
    axios
      .delete(
        `https://paulbrooksapi.doctorsforhealth.co.uk/medicines/deleteImage/${selectedRow._id}/${index}`
      )
      .then((response) => {
        setUpdatedImage(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
    setTimeout(() => {
      setPopupshow(false);
    }, 2000);
  };

  const handleImageUpload = (event) => {
    const selectedImages = Array.from(event.target.files);

    setImages([...selectedImages]);
  };

  const actionColumn = [
    { field: "medicineId", headerName: "Medicine Id", width: 150 },
    { field: "name", headerName: "Medicine Name", width: 150 },
    {
      field: "category",
      headerName: "Category",
      width: 150,
      valueGetter: (params) =>
        params.row.category ? params.row.category.name : "", // Replace 'name' with the actual property you want to display
    },
    {
      field: "genre",
      headerName: "Genre",
      width: 150,
      valueGetter: (params) => (params.row.genre ? params.row.genre.name : ""), // Replace 'name' with the actual property you want to display
    },
    { field: "price", headerName: "Retail Price", width: 150 },
    {
      field: "action",
      headerName: "Action",
      width: 400,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <div
              className="deleteButton"
              onClick={() => {
                setSelectedRow(params.row);
                setOpenModal(true);
              }}
            >
              View
            </div>
            <Link
              to={`/medicines/update/${params.id}`}
              state={{ data: params.row }}
              style={{ textDecoration: "none" }}
            >
              <div className="viewButton">Update</div>
            </Link>
            <div
              className="deleteButton"
              onClick={() => {
                setSelectedRow(params.row);
                setImageModal(true);
                setUpdatedImage(params.row.images);
              }}
            >
              Update Images
            </div>
            <div
              className="deleteButton"
              onClick={() => {
                setSelectedId(params.row._id);
                setDeleteModal(true);
              }}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div className="datatable">
      <div className="datatableTitle">
        Medicines
        <Link to="/medicines/new" className="link-new">
          Add Medicine
        </Link>
      </div>

      {deleteModal ? (
        <div className="modal">
          <div className="modalInner">
            <div style={{ margin: 40 }}>
              <SureModal
                ids={[selectedId]}
                url={`https://paulbrooksapi.doctorsforhealth.co.uk/medicines/delete/`}
                setPopupText={setPopupText}
                name="Medicine"
                setPopupShow={setPopupshow}
                setClose={setDeleteModal}
              />
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {selectedRows.length > 0 ? (
        <button
          onClick={() => {
            handleDeleteSelectedRows();
          }}
        >
          Delete Selected Rows
        </button>
      ) : null}
      {openModal ? (
        <div className="modal">
          <div className="modalInnerMedicine">
            <p className="closeModal" onClick={() => setOpenModal(false)}>
              &times;
            </p>
            <div style={{ margin: 40 }}>
              <p className="modalText">
                <span>Medicine Id:</span>
                <span> {selectedRow.medicineId}</span>
              </p>
              <p className="modalText">
                <span>Medicine Name:</span>
                <span> {selectedRow.name}</span>
              </p>
              <p className="modalText">
                <span>Retail Price:</span>
                <span> {selectedRow.price}</span>
              </p>
              {selectedRow.category ? (
                <p className="modalText">
                  <span>Category:</span>
                  <span> {selectedRow.category.name}</span>
                </p>
              ) : (
                ""
              )}
              {selectedRow.genre ? (
                <p className="modalText">
                  <span>Genre:</span>
                  <span> {selectedRow.genre.name}</span>
                </p>
              ) : (
                ""
              )}
              {selectedRow.description !== "undefined" ? (
                <div>
                  <h5>Description: </h5>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: selectedRow.description,
                    }}
                  ></div>
                </div>
              ) : (
                ""
              )}
              {selectedRow.benefits !== "undefined" ? (
                <div>
                  <h5>Benefits: </h5>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: selectedRow.benefits,
                    }}
                  ></div>
                </div>
              ) : (
                ""
              )}
              {selectedRow.sideeffects !== "undefined" ? (
                <div>
                  <h5>Side Effects: </h5>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: selectedRow.sideeffects,
                    }}
                  ></div>
                </div>
              ) : (
                ""
              )}

              <div>
                <h5>Symptoms: </h5>
                <div style={{ display: "flex" }}>
                  {"{"}
                  {selectedRow.symptoms.map((row) => row.name.name).join(", ")}
                  {"}"}
                </div>
              </div>
              {selectedRow.directions !== "undefined" ? (
                <div>
                  <h5>Directions: </h5>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: selectedRow.directions,
                    }}
                  ></div>
                </div>
              ) : (
                ""
              )}
              {selectedRow.ingredients.length > 0 ? (
                <div>
                  <h5>Ingredients: </h5>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <p style={{ color: "black", fontWeight: "bold" }}>Name</p>
                    <p style={{ color: "black", fontWeight: "bold" }}>Dosage</p>
                  </div>
                  {selectedRow.ingredients.map((row, index) => (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                      key={index}
                    >
                      <p className="modalText">{row.ingredientName}</p>
                      <p className="modalText">
                        {row.weightage} <span>{row.measurement}</span>
                      </p>
                    </div>
                  ))}
                </div>
              ) : (
                ""
              )}
              <h5 className="modalText" style={{ textAlign: "center" }}>
                Medicine Images:
              </h5>
              <div className="bannerImage">
                {selectedRow.images.map((row, index) => {
                  return (
                    <div key={index}>
                      <img
                        src={`https://paulbrooksapi.doctorsforhealth.co.uk/images/${row}`}
                        alt={row}
                        width={"100"}
                        height={"100"}
                      />
                    </div>
                  );
                })}
              </div>
              {selectedRow.bannerImage !== null ? (
                <div>
                  <h5 className="modalText" style={{ textAlign: "center" }}>
                    Medicine Banner Image:
                  </h5>
                  <div className="bannerImage">
                    <img
                      src={`https://paulbrooksapi.doctorsforhealth.co.uk/images/${selectedRow.bannerImage}`}
                      alt={selectedRow.bannerImage}
                      width={"300"}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {openImageModal ? (
        <div className="modal">
          <div className="modalInner">
            <p className="closeModal" onClick={() => setImageModal(false)}>
              &times;
            </p>
            <div style={{ margin: 40 }}>
              <h4>Update images (550x420)</h4>

              <input
                type="file"
                accept=".png, .jpg, .jpeg"
                onChange={handleImageUpload}
                multiple
              />
              <button onClick={handleAdd}>Add</button>
              <div>
                {updatedImages.map((row, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        margin: 20,

                        alignItems: "center",
                      }}
                    >
                      <img
                        src={`https://paulbrooksapi.doctorsforhealth.co.uk/images/${row}`}
                        alt={row}
                        width={"50"}
                        height={"50"}
                      />
                      <p
                        className="deleteMedImages"
                        onClick={(e) => {
                          e.preventDefault();
                          handleDeleteImage(index);
                        }}
                      >
                        &times;
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {popUpShow ? (
        <PopupAlert popUpText={popUpText} backgroundColor={"red"} />
      ) : (
        ""
      )}

      {loading ? (
        <CircularProgress />
      ) : (
        <DataGrid
          className="datagrid"
          rows={medicines}
          columns={actionColumn}
          checkboxSelection={true}
          onSelectionModelChange={(newSelection) => {
            setSelectedRows(newSelection);
          }}
          getRowId={(row) => {
            return row._id;
          }}
          pageSize={9}
          rowsPerPageOptions={[9]}
        />
      )}
    </div>
  );
};

export default DatatableMedicines;
