import "../../style/datatable.css";
import { DataGrid } from "@mui/x-data-grid";
import { userColumns } from "../../datatablesource";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";
import PopupAlert from "../../components/popupalert/popupAlert";
import { CircularProgress } from "@mui/material";
import SureModal from "../../components/SureModal/SureModal";

const DatatableUsers = () => {
  const [users, setUsers] = useState([]);
  const [popUpShow, setPopupshow] = useState(false);
  const [popUpText, setPopupText] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedId, setSelectedId] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);

  useEffect(() => {
    axios
      .get("https://paulbrooksapi.doctorsforhealth.co.uk/users")
      .then((response) => {
        const users = response.data.filter((user) => user.doctor === false);
        setUsers(users);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleDelete = (id) => {
    axios
      .delete("https://paulbrooksapi.doctorsforhealth.co.uk/users/" + id)
      .then((response) => {
        console.log(response.data);
      });
    setUsers(users.filter((el) => el._id !== id));
    setPopupshow(true);
    setPopupText("User Deleted");
    setTimeout(() => {
      setPopupshow(false);
    }, 2000);
  };

  const handleDeleteSelectedRows = () => {
    selectedRows.forEach((row) => {
      axios
        .delete("https://paulbrooksapi.doctorsforhealth.co.uk/users/" + row)
        .then((response) => {
          setUsers(response.data);
          setPopupshow(true);
          setPopupText(`${selectedRows.length} Users Deleted`);
        });
    });
    setTimeout(() => {
      setPopupshow(false);
    }, 2000);
    setSelectedRows([]);
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 180,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link
              to={`/users/update/${params.id}`}
              state={{ data: params.row }}
              style={{ textDecoration: "none" }}
            >
              <div className="viewButton">Update</div>
            </Link>
            <div
              className="deleteButton"
              onClick={() => {
                setSelectedId([params.row._id]);
                setDeleteModal(true);
              }}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];
  return (
    <div className="datatable">
      <div className="datatableTitle">Users</div>

      {deleteModal ? (
        <div className="modal">
          <div className="modalInner">
            <div style={{ margin: 40 }}>
              <SureModal
                ids={[selectedId]}
                url={`https://paulbrooksapi.doctorsforhealth.co.uk/users/`}
                setPopupText={setPopupText}
                name={selectedId.length <= 1 ? "User" : "Users"}
                setPopupShow={setPopupshow}
                setClose={setDeleteModal}
              />
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {selectedId.length > 1 ? (
        <button
          onClick={() => {
            setDeleteModal(true);
          }}
        >
          Delete Selected Rows
        </button>
      ) : null}
      {popUpShow ? (
        <PopupAlert popUpText={popUpText} backgroundColor={"red"} />
      ) : (
        ""
      )}
      {loading ? (
        <CircularProgress />
      ) : (
        <DataGrid
          className="datagrid"
          rows={users}
          columns={userColumns.concat(actionColumn)}
          checkboxSelection={true}
          onSelectionModelChange={(newSelection) => {
            setSelectedId(newSelection);
          }}
          getRowId={(row) => {
            return row._id;
          }}
          pageSize={9}
          rowsPerPageOptions={[9]}
        />
      )}
    </div>
  );
};

export default DatatableUsers;
