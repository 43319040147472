import "../../style/new.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import PopupAlert from "../../components/popupalert/popupAlert";
import Select from "react-select";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingModel from "./LoadingModel";
const { uid } = require("uid");

const RandomDigit = uid();

const randomString = RandomDigit.replace(/[^0-9a-zA-Z]/g, "") // remove non-digits
  .substring(0, 7); // extract first 5 digits

const UpdateMedicine = ({ title }) => {
  const location = useLocation();
  const medicineData = location.state.data;
  const [name, setName] = useState(medicineData.name);
  const [bannerImage, setFile] = useState();
  const [description, setDescription] = useState(medicineData.description);
  const [benefits, setBenefits] = useState(medicineData.benefits);
  const [sideeffects, setSideEffects] = useState(medicineData.sideeffects);
  const [directions, setDirections] = useState(medicineData.directions);
  const [ingredients, setingredients] = useState(medicineData.ingredients);
  const [category, setcategory] = useState("");
  const [genre, setGenre] = useState("");
  const [symptoms, setsymptoms] = useState([]);
  const [popUpShow, setPopupshow] = useState(false);
  const [popUpText, setPopupText] = useState("");
  const [allCategories, setAllCategories] = useState([]);
  const [allGenres, setAllGenres] = useState([]);
  const [allSymptoms, setAllSymptoms] = useState([]);
  const [ingredientName, setIngredientName] = useState("");
  const [weightage, setWeightage] = useState();
  const [measurement, setMeasurement] = useState("");
  const [price, setPrice] = useState(medicineData.price);
  const [allMeasurements, setAllMeasurement] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (medicineData.category) {
      setcategory(medicineData.category._id);
    }
    if (medicineData.genre) {
      setGenre(medicineData.genre._id);
    }
    axios
      .get("https://paulbrooksapi.doctorsforhealth.co.uk/categories")
      .then((response) => {
        if (response.data.length > 0) {
          setAllCategories(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get("https://paulbrooksapi.doctorsforhealth.co.uk/genres")
      .then((response) => {
        if (response.data.length > 0) {
          setAllGenres(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get("https://paulbrooksapi.doctorsforhealth.co.uk/symptoms")
      .then((response) => {
        if (response.data.length > 0) {
          setAllSymptoms(response.data);
          const symptomNames = medicineData.symptoms.map(
            (symptom) => symptom.name.name
          );
          const filteredSymptoms = response.data
            .filter((symptom) => symptomNames.includes(symptom.name))
            .map((symptom) => ({
              _id: symptom._id,
              label: symptom.name,
              value: symptom.name,
            }));
          setsymptoms(filteredSymptoms);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get("https://paulbrooksapi.doctorsforhealth.co.uk/measurements")
      .then((response) => {
        setAllMeasurement(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const quillModules = {
    toolbar: [
      ["bold", "italic", "underline", "strike"], // text formatting options
      [{ header: [1, 2, 3, 4, 5, 6, false] }], // header styles
      [{ list: "ordered" }, { list: "bullet" }], // lists
      ["link", "image"], // link and image options
      [{ color: [] }], // color option
      [{ align: [] }], // text alignment option
      [{ size: ["small", false, "large", "huge"] }],
      [{ font: [] }], // custom font style option
      ["clean"], // remove formatting
    ],
  };

  const quillFormats = [
    "bold",
    "italic",
    "underline",
    "strike",
    "header",
    "list",
    "bullet",
    "link",
    "image",
    "color",
    "align",
    "font",
    "size",
  ];

  const handleDeleteIngredient = (indexToRemove) => {
    // Create a copy of the ingredients array without the item at the specified index
    const updatedIngredients = [...ingredients];
    updatedIngredients.splice(indexToRemove, 1);

    // Update the state with the new array of ingredients
    setingredients(updatedIngredients);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("name", name);
    formData.append("description", description);
    formData.append("benefits", benefits);
    symptoms.map((symptom, index) => {
      formData.append(`symptoms[${index}][name]`, symptom._id);
      console.log(symptom);
    });

    ingredients.forEach((ingredient, index) => {
      formData.append(
        `ingredients[${index}][ingredientName]`,
        ingredient.ingredientName
      );
      formData.append(`ingredients[${index}][weightage]`, ingredient.weightage);
      formData.append(
        `ingredients[${index}][measurement]`,
        ingredient.measurement
      );
    });

    formData.append("sideeffects", sideeffects);
    formData.append("category", category);
    formData.append("genre", genre);
    formData.append("medicineId", randomString);
    formData.append("directions", directions);
    formData.append("bannerImage", bannerImage);
    formData.append("price", price);

    axios
      .post(
        `https://paulbrooksapi.doctorsforhealth.co.uk/medicines/update/${medicineData._id}`,
        formData
      )
      .then((response) => {
        setLoading(false);
        setPopupshow(true);
        setPopupText("Medicine Updated");
        setTimeout(() => {
          setPopupshow(false);
          navigate("/medicines");
        }, 1500);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          alert(error.response.data);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
      });
  };

  const handleBannerImageUpload = (event) => {
    setFile(event.target.files[0]);
  };

  const addIngredients = () => {
    if (!ingredientName || !measurement || !weightage) {
      alert("Please fill all the ingredients input");
    } else {
      setingredients([
        ...ingredients,
        {
          ingredientName: ingredientName,
          weightage: weightage,
          measurement: measurement,
        },
      ]);
      setIngredientName("");
      setWeightage("");
    }
  };
  const updatedSymptoms = allSymptoms.map((item) => ({
    _id: item._id,
    label: item.name,
    value: item.name,
  }));

  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        {loading ? (
          <div className="modal">
            <div className="modalInner" style={{ border: "none" }}>
              <LoadingModel />
            </div>
          </div>
        ) : (
          ""
        )}
        {popUpShow ? (
          <PopupAlert popUpText={popUpText} backgroundColor={"orange"} />
        ) : (
          ""
        )}

        <div className="top-new">
          <h1 className="heading-top">{title}</h1>
        </div>
        <div className="bottom">
          <div className="right">
            <form
              className="form-new"
              onSubmit={handleSubmit}
              method="post"
              encType="multipart/form-data"
              action="/upload"
            >
              <div className="formInput">
                {/* Name */}
                <label className="label-form">Medicine Name</label>
                <input
                  type="text"
                  placeholder="Health Care"
                  className="input-form"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
                <label className="label-form">Retail Price*</label>
                <input
                  type="number"
                  placeholder="45"
                  className="input-form"
                  value={price}
                  onChange={(e) => {
                    setPrice(e.target.value);
                  }}
                />
                {/* Categories */}
                <label className="label-form">Medicine Category</label>
                <select
                  value={category}
                  onChange={(e) => {
                    setcategory(e.target.value);
                  }}
                  className="input-form"
                >
                  {allCategories.map((row) => (
                    <option value={row._id} key={row.name}>
                      {row.name}
                    </option>
                  ))}
                </select>
                {/* Genre */}
                <label className="label-form">Medicine Genre*</label>
                <select
                  value={genre}
                  onChange={(e) => {
                    setGenre(e.target.value);
                  }}
                  className="input-form"
                >
                  <option value=""></option>
                  {allGenres.map((row) => (
                    <option value={row._id} key={row.name}>
                      {row.name}
                    </option>
                  ))}
                </select>
                {/*Symptoms*/}
                <label className="label-form">Medicine Symptoms</label>
                <Select
                  options={updatedSymptoms}
                  isMulti
                  value={symptoms}
                  onChange={(selected) => setsymptoms(selected)}
                />
                {/* Description */}
                <label htmlFor="description" className="label-form">
                  Medicine Description:
                </label>
                <ReactQuill
                  value={description}
                  modules={quillModules}
                  formats={quillFormats}
                  placeholder="Write something..."
                  onChange={(value) => setDescription(value)}
                />
                {/* Ingredients*/}
                <label className="label-form">Medicine Ingredients*</label>

                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <input
                    type="text"
                    placeholder="Ingredient Name"
                    className="input-form ingrdientNameInput"
                    value={ingredientName}
                    onChange={(e) => {
                      setIngredientName(e.target.value);
                    }}
                  />
                  <input
                    type="number"
                    placeholder="5 mg"
                    className="input-form weightageInput"
                    style={{ width: 120 }}
                    value={weightage}
                    onChange={(e) => {
                      setWeightage(e.target.value);
                    }}
                  />
                  <select
                    value={measurement}
                    onChange={(e) => setMeasurement(e.target.value)}
                  >
                    <option value=""></option>
                    {allMeasurements.map((row) => (
                      <option value={row.name} key={row._id}>
                        {row.name}
                      </option>
                    ))}
                  </select>

                  <div
                    onClick={addIngredients}
                    style={{
                      backgroundColor: "black",
                      color: "white",
                      cursor: "pointer",
                      padding: 5,
                    }}
                  >
                    Add
                  </div>
                </div>
                <ul>
                  {ingredients.map((item, index) => (
                    <li
                      key={index}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p>{item.ingredientName}</p>

                      <p>
                        {item.weightage} <span> {item.measurement}</span>
                      </p>
                      <p
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          e.preventDefault();
                          handleDeleteIngredient(index);
                        }}
                      >
                        X
                      </p>
                    </li>
                  ))}
                </ul>

                {/* Benefits*/}
                <label className="label-form">Medicine Benefits</label>
                <ReactQuill
                  value={benefits}
                  modules={quillModules}
                  formats={quillFormats}
                  placeholder="Write something..."
                  onChange={(value) => setBenefits(value)}
                />
                {/* SideEffects*/}
                <label className="label-form">Medicine Side-effects</label>
                <ReactQuill
                  value={sideeffects}
                  modules={quillModules}
                  formats={quillFormats}
                  placeholder="Write something..."
                  onChange={(value) => setSideEffects(value)}
                />
                {/* Directions*/}
                <label className="label-form">Medicine Directions</label>
                <ReactQuill
                  modules={quillModules}
                  formats={quillFormats}
                  placeholder="Write something..."
                  value={directions}
                  onChange={(value) => setDirections(value)}
                />

                {/* Banner Image */}
                <label className="label-form">
                  Medicine Banner Image (PNG/JPEG/JPG) (375x135)
                </label>
                <input
                  type="file"
                  accept=".png, .jpg, .jpeg"
                  onChange={handleBannerImageUpload}
                />

                {/*Sumit Buttom*/}
                <button className="createButton">Update</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateMedicine;
