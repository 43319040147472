import "../../style/new.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useEffect, useState } from "react";
import axios from "axios";
import PopupAlert from "../../components/popupalert/popupAlert";

const AddBlock = ({ title }) => {
  const [name, setName] = useState("");
  const [popUpShow, setPopupshow] = useState(false);
  const [popUpText, setPopupText] = useState("");
  const [medicineData, setMedicineData] = useState([]);
  const [errorShow, setErrorShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [allMedicines, setAllMedicines] = useState([]);

  useEffect(() => {
    axios
      .get("https://paulbrooksapi.doctorsforhealth.co.uk/medicines")
      .then((response) => {
        setAllMedicines(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [allMedicines]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = { name: name, medicineData: medicineData };
    if (medicineData.length > 0) {
      axios
        .post("https://paulbrooksapi.doctorsforhealth.co.uk/blocks", data)
        .then((response) => {
          if (response.data.error === "This block already exists") {
            setErrorShow(true);
            errorMessage("This block already exists");
            setName("");
          } else {
            setName("");
            setPopupshow(true);
            setPopupText("Block Added");
            setErrorShow(false);

            setTimeout(() => {
              setPopupshow(false);
              window.location.reload();
            }, 2000);
          }
        })
        .catch((error) => {
          console.error(error);
          setErrorShow(true); // will log "Category already exists"
          errorMessage("This block already exists");
        });
    } else {
      setErrorShow(true);
      setErrorMessage("Alteast 1 medicine data is mandatory");
    }
  };

  // Function to handle checkbox changes
  const handleCheckboxChange = (rowId) => {
    const isChecked = medicineData.includes(rowId);

    if (isChecked) {
      // If rowId is already in medicineData, remove it
      const updatedMedicineData = medicineData.filter((id) => id !== rowId);
      setMedicineData(updatedMedicineData);
    } else {
      // If rowId is not in medicineData, add it
      const updatedMedicineData = [...medicineData, rowId];
      setMedicineData(updatedMedicineData);
    }
  };

  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        {popUpShow ? (
          <PopupAlert popUpText={popUpText} backgroundColor={"#8AFF8A"} />
        ) : (
          ""
        )}
        <div className="top-new">
          <h1 className="heading-top">{title}</h1>
        </div>
        <div className="bottom">
          <div className="right">
            {errorShow ? (
              <div style={{ color: "green", fontSize: 15 }}>{errorMessage}</div>
            ) : null}
            <form
              className="form-new"
              onSubmit={handleSubmit}
              method="post"
              encType="multipart/form-data"
              action="/upload"
            >
              <div className="formInput">
                <label className="label-form">Block Name*</label>
                <input
                  type="text"
                  placeholder="Health Care"
                  className="input-form"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  required
                />
                <label className="label-form">Block Medicines*</label>

                {allMedicines.map((row, index) => {
                  const isChecked = medicineData.includes(row._id);

                  return (
                    <div
                      key={index}
                      style={{
                        width: "100%",
                        margin: 10,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <input
                        type="checkbox"
                        checked={isChecked}
                        onChange={() => handleCheckboxChange(row._id)}
                      />
                      <img
                        src={`https://paulbrooksapi.doctorsforhealth.co.uk/images/${row.images[0]}`}
                        alt={row.images[0]}
                        width={50}
                        height={50}
                        style={{ marginLeft: 20 }}
                      />
                      <p style={{ color: "black", marginLeft: 20 }}>
                        {row.name}
                      </p>
                    </div>
                  );
                })}
                <button className="createButton">Add</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddBlock;
