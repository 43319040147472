import axios from "axios";
import React from "react";

const SureModal = ({
  ids,
  setClose,
  url,
  name,
  setPopupText,
  setPopupShow,
}) => {
  const handleDelete = (e) => {
    e.preventDefault();
    try {
      ids.forEach((row) => {
        axios.delete(url + row);
      });
      setPopupShow(true);
      setPopupText(`${name} deleted`);
      setTimeout(() => {
        setPopupShow(false);
        setClose(false);
        window.location.reload();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <h5 style={{ textAlign: "center" }}>Are you sure?</h5>
      <div style={{ margin: "20px" }}>
        <button
          style={{
            border: "none",
            margin: 10,
            color: "white",
            backgroundColor: "red",
            padding: 5,
            cursor: "pointer",
          }}
          onClick={handleDelete}
        >
          Delete
        </button>
        <button
          style={{
            border: "none",
            margin: 10,
            color: "white",
            backgroundColor: "blue",
            padding: 5,
            cursor: "pointer",
          }}
          onClick={() => setClose(false)}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default SureModal;
