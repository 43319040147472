import "../../style/datatable.css";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { CircularProgress } from "@mui/material";
import PopupAlert from "../../components/popupalert/popupAlert";
import SureModal from "../../components/SureModal/SureModal";

const DatatableCategories = () => {
  const [categories, setcategories] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [loading, setLoading] = useState(true);
  const [popUpShow, setPopupshow] = useState(false);
  const [popUpText, setPopupText] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedId, setSelectedId] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);

  useEffect(() => {
    axios
      .get("https://paulbrooksapi.doctorsforhealth.co.uk/categories")
      .then((response) => {
        setcategories(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [categories]);

  const handleDeleteSelectedRows = () => {
    selectedRows.forEach((row) => {
      axios
        .delete(
          "https://paulbrooksapi.doctorsforhealth.co.uk/categories/" + row
        )
        .then((response) => {
          setcategories(response.data);
          setPopupshow(true);
          setPopupText(`${selectedRows.length} categories Deleted`);
        });
    });
    setTimeout(() => {
      setPopupshow(false);
    }, 2000);
    setSelectedRows([]);
  };

  const actionColumn = [
    { field: "name", headerName: "Category Name", width: 200 },
    { field: "description", headerName: "Description", width: 200 },
    {
      field: "image",
      headerName: "Image",
      width: 300,
      renderCell: (params) => {
        return (
          <div
            onClick={() => {
              setSelectedRow(params.row);
              console.log(params.row);
              setOpenModal(true);
            }}
          >
            <img
              src={`https://paulbrooksapi.doctorsforhealth.co.uk/categories/images/${params.row.image}`}
              alt={params.row.image}
              width={"40"}
              height={"40"}
              className="imageInCategory"
            />
          </div>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 300,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link
              to={`/categories/update/${params.id}`}
              style={{ textDecoration: "none" }}
            >
              <div className="viewButton">Update</div>
            </Link>
            <div
              className="deleteButton"
              onClick={() => {
                setSelectedId(params.row._id);
                setDeleteModal(true);
              }}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];
  return (
    <div className="datatable">
      {popUpShow ? (
        <PopupAlert popUpText={popUpText} backgroundColor={"red"} />
      ) : (
        ""
      )}
      <div className="datatableTitle">
        Categories
        <Link to="/categories/new" className="link-new">
          Add Category
        </Link>
      </div>
      {selectedRows.length > 0 ? (
        <button
          onClick={() => {
            handleDeleteSelectedRows();
          }}
        >
          Delete Selected Rows
        </button>
      ) : null}
      {deleteModal ? (
        <div className="modal">
          <div className="modalInner">
            <div style={{ margin: 40 }}>
              <SureModal
                ids={[selectedId]}
                url={`https://paulbrooksapi.doctorsforhealth.co.uk/categories/`}
                setPopupText={setPopupText}
                name="Category"
                setPopupShow={setPopupshow}
                setClose={setDeleteModal}
              />
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {openModal ? (
        <div className="modal">
          <div className="modalInner">
            <p className="closeModal" onClick={() => setOpenModal(false)}>
              &times;
            </p>
            <div style={{ margin: 40 }}>
              <img
                src={`https://paulbrooksapi.doctorsforhealth.co.uk/categories/images/${selectedRow.image}`}
                alt={selectedRow.image}
                width={"400"}
                height={"400"}
              />
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {loading ? (
        <CircularProgress />
      ) : (
        <DataGrid
          className="datagrid"
          rows={categories}
          columns={actionColumn}
          checkboxSelection={true}
          onSelectionModelChange={(newSelection) => {
            setSelectedRows(newSelection);
          }}
          getRowId={(row) => {
            return row._id;
          }}
          pageSize={9}
          rowsPerPageOptions={[9]}
        />
      )}
    </div>
  );
};

export default DatatableCategories;
